import { ColourAtoms } from "@buzzbike/ui/src/DesignSystem";
import { Box } from "@chakra-ui/react";

import TrustBox from "components/TrustBox";

import { WEBSITE_URL } from "../../constants";
import { Logo } from "../Logo";

type NavProps = {
  bgColor?: "pink" | "green";
  logoColor?: "pink" | "white" | "green";
  isTexture?: boolean;
  withTrustBox?: boolean;
};

function SignupNavbar({
  bgColor = "pink",
  logoColor = "white",
  isTexture = false,
  withTrustBox,
}: NavProps) {
  const backgroundColor =
    bgColor === "green" ? ColourAtoms.BB.Emerald : ColourAtoms.BB.Pink;
  const texturePath = `/static/images/nav/texture-${bgColor}.png`;
  return (
    <Box
      bgColor={{ base: backgroundColor, md: "transparent" }}
      bgImage={{ base: isTexture ? `url(${texturePath})` : "", md: "none" }}
      backgroundSize="cover"
      backgroundPosition="bottom"
      w={["full", "full", "fit-content"]}
      h={["90px", "90px", "fit-content"]}
      position={{ base: "absolute", md: "fixed" }}
      clipPath={{
        base: "polygon(100% 0, 100% 75%, 50% 90%, 40% 70%, 0 100%, 0 0)",
        md: "none",
      }}
      zIndex="1"
    >
      <Logo
        iconColor={
          logoColor === "white"
            ? ColourAtoms.BB.White
            : logoColor === "pink"
            ? ColourAtoms.BB.Pink
            : ColourAtoms.BB.Emerald
        }
        ml={6}
        mt={7}
        w={{ base: 40, md: 52 }}
        cursor="pointer"
        onClick={() => {
          window.open(WEBSITE_URL, "_self");
        }}
      />
      {withTrustBox && (
        <Box mt={4} ml={1}>
          <TrustBox theme="dark" templateId="5419b732fbfb950b10de65e5" />
        </Box>
      )}
    </Box>
  );
}

export default SignupNavbar;
