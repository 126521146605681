import * as Sentry from "@sentry/react";
import { format, isMatch, parse, parseISO, subYears } from "date-fns";
import { GetServerSideProps, GetServerSidePropsContext, Redirect } from "next";
import { getSession } from "next-auth/client";
import nookies, { setCookie } from "nookies";
import { ParsedUrlQuery } from "querystring";
import * as yup from "yup";
import { TypeOf } from "yup";

import {
  BBEZ_WAITLIST,
  BBEZ_WAITLIST_EXCLUDE_CITY,
  DeliveryOption,
  INVALID_POSTCODE_MESSAGE,
} from "../constants";
import { makeQueryString } from "./endpoints";
import { formatInTimeZone } from "./time";
import {
  checkDeliveryAvailability,
  checkPhoneNumber,
  checkValidPostCode,
} from "./validateHelpers";

type CookieJar = {
  [key: string]: string;
};

type AnalyticEvent = "rewind" | "options-change-url" | "fast-forward";

type PageName = "account" | "plan" | "payment" | "kitshop" | "city";

type CheckoutCookiesName = "checkout";

export const accountSchema = yup.object({
  email: yup
    .string()
    .email("Please provide a valid email")
    .required("This field is required"),
  firstName: yup.string().required("This field is required"),
  lastName: yup.string().required("This field is required"),
  dateOfBirth: yup
    .string()
    .required("This field is required")
    .test(
      "is-vaild",
      "Please input a valid date in the format DD/MM/YYYY",
      (originalValue) => {
        return isMatch(originalValue ?? "", "dd/MM/yyyy");
      }
    )
    .test("is-min", "Please input a date from 01/01/1900", (originalValue) => {
      const value = parse(originalValue ?? "", "dd/MM/yyyy", new Date());
      const base = parse("01/01/1900", "dd/MM/yyyy", new Date());
      return value > base;
    })
    .test(
      "is-max",
      "You must be over 18 to become a Buzzbike member",
      (originalValue) => {
        const value = parse(originalValue ?? "", "dd/MM/yyyy", new Date());
        const eighteen = subYears(new Date(), 18);
        return value < eighteen;
      }
    ),

  home_postcode: yup.string(),
  home_address_line_one: yup.string(),
  home_address_line_two: yup.string(),
  home_city: yup.string(),
  home_flat: yup.string(),
  home_house_number: yup.string(),
  home_house_name: yup.string(),
  home_street: yup.string(),
  home_street_2: yup.string(),
  home_district: yup.string(),
  home_county: yup.string(),

  isLoggedIn: yup.boolean(),
  password: yup.string().when("isLoggedIn", {
    is: true,
    then: yup.string(),
    otherwise: yup
      .string()
      .min(8, "At least 8 characters needed")
      .required("This field is required"),
  }),
  deliveryOption: yup
    .string()
    .oneOf(
      [DeliveryOption.DeliveryToDoor, DeliveryOption.PickUp],
      "This field is required"
    )
    .required("This field is required"),
  buzzbikeRetailId: yup.number(),
  delivery_postcode: yup
    .string()
    .required("Postcode is required")
    .test("is-valid", INVALID_POSTCODE_MESSAGE, checkValidPostCode),
  delivery_address_line_one: yup.string().required("This field is required"),
  delivery_address_line_two: yup.string(),
  delivery_city: yup.string().required("City is required"),

  delivery_flat: yup.string(),
  delivery_house_name: yup.string(),
  delivery_house_number: yup.string().when("delivery_house_name", {
    is: (val?: string | null) => val && val.length > 0,
    then: yup.string(),
    otherwise: yup.string().required("House Name or House Number is required"),
  }),
  delivery_street: yup.string(),
  delivery_street_2: yup.string(),
  delivery_district: yup.string().required("District is required"),
  delivery_county: yup.string().required("County is required"),

  delivery_date: yup
    .date()
    .typeError("Invalid date")
    .required("This field is required")
    .test(
      "is-available-dates",
      "This date is not available",
      checkDeliveryAvailability
    ),
  delivery_note: yup.string(),
  phone_number_mobile: yup
    .string()
    .min(15, "Please enter a valid UK number") // include the contry code and space
    .required("This field is required")
    .test(
      "is-phone-number-validate",
      "Please enter a valid UK number",
      checkPhoneNumber
    ),
  pickup_time: yup.string().when("deliveryOption", {
    is: DeliveryOption.DeliveryToDoor,
    then: yup.string(),
    otherwise: yup.string().required("This field is required"),
  }),
  height: yup
    .boolean()
    .oneOf([true], "This field is required")
    .required("This field is required"),
  commercialAgreement: yup
    .boolean()
    .oneOf(
      [true],
      "You need to agree to not use the Buzzbike for commercial purposes"
    )
    .required(
      "You need to agree to not use the Buzzbike for commercial purposes"
    ),
  termAgreement: yup
    .boolean()
    .oneOf([true], "You need to read and agree to the terms of use")
    .required("You need to read and agree to the terms of use"),
  marketingOptIn: yup.boolean(),
});

export const planSchema = yup.object({
  bikeId: yup.number().required("This is a required field"),
  bikeName: yup.string().required("This is a required field"),
  plan: yup.string().required("This is a required field"),
  isBikeE: yup.boolean(),
  planPrice: yup.number(),
  deliveryFeeInPence: yup.number(),
  isFullTheftCoverage: yup.boolean(),
});

export const kitshopSchema = yup.object({
  kitItems: yup.array().of(
    yup
      .object({
        kitInventoryId: yup.number().required(),
        quantity: yup.number().required(),
        price: yup.number().required(),
      })
      .required()
  ),
});

export const citySchema = yup.object({
  cityId: yup.number().required("This is a required field"),
  cityName: yup.string(),
  validPostcode: yup.object({
    postalAreaList: yup.string(),
    postalDistrictList: yup.string(),
  }),
});

export const fullSchema = accountSchema
  .concat(planSchema)
  .concat(kitshopSchema)
  .concat(citySchema);

export type FlowData = TypeOf<typeof fullSchema>;

type UrlQuery = ParsedUrlQuery & {
  id?: string;
  promo_code?: string;
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_term?: string;
};

type PageConfig<TProps, TFlowData, TUser> = {
  mustBeLoggedIn: boolean;
  previousActivatedSteps: readonly PageName[];
  validateStep: (data: TFlowData, urlParams: ParsedUrlQuery) => Promise<void>;
  getPageProps: (
    data: TFlowData,
    urlParams: ParsedUrlQuery,
    currentUser: TUser | undefined
  ) => Promise<TProps>;
} & (
  | {
      nextStep: PageName;
      canBeSkipped: (
        data: TFlowData,
        currentUser: TUser | undefined,
        urlParams: ParsedUrlQuery
      ) => Promise<boolean>;
    }
  | { nextStep: false }
);

const configs = {
  city: {
    mustBeLoggedIn: false,
    previousActivatedSteps: [],
    validateStep: async (data: FlowData) => {
      await citySchema.validate(data);
    },
    getPageProps: async (data: FlowData) => {
      const props = {
        cityId: data.cityId ?? null,
      };
      try {
        citySchema.validateSyncAt("cityId", props);
      } catch {
        props.cityId = null;
      }

      return props;
    },
    nextStep: "plan",
    canBeSkipped: async (
      _data: FlowData,
      _currentUser: CurrentUser,
      _urlParams: ParsedUrlQuery
    ) => {
      return false; // (await citySchema.isValid(data)) && urlParams.isBack !== "true";
    },
  },
  plan: {
    mustBeLoggedIn: false,
    previousActivatedSteps: ["city"],
    validateStep: async (data: FlowData, urlParams: ParsedUrlQuery) => {
      await planSchema.validate(data);
      if (
        BBEZ_WAITLIST &&
        data.isBikeE &&
        data.cityName !== BBEZ_WAITLIST_EXCLUDE_CITY &&
        urlParams.fromBBEWaitingList !== "true"
      ) {
        throw new Error("Waitlist Validation");
      }
    },
    getPageProps: async (data: FlowData) => {
      return {
        ...removeUndefinedForNextJsSerializing(data),
      };
    },
    nextStep: "account",
    canBeSkipped: async (
      data: FlowData,
      _currentUser: CurrentUser,
      urlParams: ParsedUrlQuery
    ) => {
      return (
        (await planSchema.isValid(data)) &&
        urlParams.isBack !== "true" &&
        !(
          BBEZ_WAITLIST &&
          data.isBikeE &&
          data.cityName !== BBEZ_WAITLIST_EXCLUDE_CITY &&
          urlParams.fromBBEWaitingList !== "true"
        )
      );
    },
  },
  account: {
    mustBeLoggedIn: false,
    validateStep: async (data: FlowData) => {
      await accountSchema.validate(data);
    },
    getPageProps: async (
      data: FlowData,
      _urlParams: ParsedUrlQuery,
      _currentUser: CurrentUser
    ) => {
      return {
        ...removeUndefinedForNextJsSerializing(data),
      };
    },
    nextStep: "kitshop",
    canBeSkipped: async (
      data: FlowData,
      _currentUser: CurrentUser,
      urlParams: ParsedUrlQuery
    ) => {
      return (await accountSchema.isValid(data)) && urlParams.isBack !== "true";
    },
    previousActivatedSteps: ["city", "plan"],
  },
  kitshop: {
    mustBeLoggedIn: false,
    previousActivatedSteps: ["city", "plan", "account"],
    validateStep: async (data: FlowData) => {
      await kitshopSchema.validate(data);
    },
    getPageProps: async (data: FlowData, _urlParams: ParsedUrlQuery) => {
      return removeUndefinedForNextJsSerializing(data);
    },
    nextStep: "payment",
    canBeSkipped: async (
      _data: FlowData,
      _currentUser: CurrentUser,
      _urlParams: ParsedUrlQuery
    ) => {
      return false;
    },
  },
  payment: {
    mustBeLoggedIn: true,
    previousActivatedSteps: ["city", "plan", "account", "kitshop"],
    validateStep: async () => {
      return;
    },
    nextStep: false,
    getPageProps: async (data: FlowData) => {
      return {
        delivery_date: data.delivery_date,
        delivery_postcode: data.delivery_postcode || "",
        delivery_address_line_one: data.delivery_address_line_one || "",
        delivery_address_line_two: data.delivery_address_line_two,
        delivery_city: data.delivery_city || "",
        plan: data.plan,
        email: data.email,
        delivery_note: data.delivery_note,
        selectedKitItems: data.kitItems,
        cityId: data.cityId,
        bikeId: data.bikeId,
        isBikeE: data.isBikeE,
        bikeName: data.bikeName,
        buzzbikeRetailId: data.buzzbikeRetailId,
        pickup_time: data.pickup_time,
        home_postcode: data.home_postcode || "",
        home_address_line_one: data.home_address_line_one || "",
        home_address_line_two: data.home_address_line_two,
        home_city: data.home_city || "",
        cityName: data.cityName || "",
        delivery_flat: data.delivery_flat,
        delivery_house_name: data.delivery_house_name,
        delivery_house_number: data.delivery_house_number,
        delivery_street: data.delivery_street,
        delivery_street_2: data.delivery_street_2,
        delivery_district: data.delivery_district,
        delivery_county: data.delivery_county,
        isFullTheftCoverage: data.isFullTheftCoverage,
      } as {
        delivery_date: Date;
        delivery_postcode: string;
        delivery_address_line_one: string;
        delivery_address_line_two: string;
        delivery_city: string;
        plan: string;
        email: string;
        delivery_note?: string;
        selectedKitItems: SelectedKit[] | undefined;
        cityId: number;
        bikeId: number;
        bikeName: string;
        isBikeE?: boolean;
        buzzbikeRetailId?: number;
        pickup_time?: string;
        home_postcode: string;
        home_address_line_one: string;
        home_address_line_two: string;
        home_city: string;
        cityName?: string;
        delivery_flat?: string;
        delivery_house_name?: string;
        delivery_house_number?: string;
        delivery_street?: string;
        delivery_street_2?: string;
        delivery_district?: string;
        delivery_county?: string;
        isFullTheftCoverage?: boolean;
      };
    },
  },
} as const;

type CurrentUser =
  | (Pick<
      FlowData,
      | "firstName"
      | "lastName"
      | "email"
      | "phone_number_mobile"
      | "dateOfBirth"
      | "home_address_line_one"
      | "home_address_line_two"
      | "home_city"
      | "home_postcode"
    > & {
      hasSubscription: boolean;
      isRejoiner: boolean;
      requestUrl?: string;
    })
  | undefined;

interface PreCheckoutPageParams<TPageName> {
  cookies: CookieJar;
  pageName: TPageName;
  urlParams: ParsedUrlQuery;
  currentUser?: CurrentUser;
}

function getFlowData(
  checkoutCookieName: CheckoutCookiesName,
  cookies: CookieJar,
  currentUser?: CurrentUser
): FlowData {
  try {
    const cookieData = fullSchema.cast(cookies[checkoutCookieName]);
    if (currentUser) {
      const userData = fullSchema.cast(currentUser);
      return { ...userData, ...cookieData };
    }
    return cookieData;
  } catch (error) {
    destroyCheckoutCookies(checkoutCookieName);
    return fullSchema.cast(undefined);
  }
}

function applyValuesFromUrlParams(
  urlParams: ParsedUrlQuery,
  flowData: FlowData
): FlowData | null {
  if (urlParams.plan || urlParams.email) {
    const { plan, email } = urlParams;
    return {
      ...flowData,
      plan: plan ? (Array.isArray(plan) ? plan[0] : plan) : flowData.plan,
      email: email ? (Array.isArray(email) ? email[0] : email) : flowData.email,
    } as FlowData;
  }
  return null;
}

function updateCheckoutCookies(
  checkoutCookieName: CheckoutCookiesName,
  cookieChanges: CookieJar,
  flowData: FlowData
) {
  cookieChanges[checkoutCookieName] = JSON.stringify(flowData);
}

export async function updateCheckoutCookiesFromClient(
  checkoutCookieName: CheckoutCookiesName,
  flowData: Partial<FlowData>
) {
  const cookies = await getCookies();
  const cookieChanges: CookieJar = {};
  updateCheckoutCookies(checkoutCookieName, cookieChanges, {
    ...getFlowData(checkoutCookieName, cookies),
    ...flowData,
  });
  applyCookieChanges(cookieChanges);
}

export function destroyCheckoutCookies(
  checkoutCookieName: CheckoutCookiesName,
  context?: GetServerSidePropsContext
) {
  nookies.destroy(context, checkoutCookieName, { path: "/" });
}

type PropsTypeFromPageConfig<T> = T extends PageConfig<infer U, never, never>
  ? U
  : never;

export async function preCheckoutPage<
  TPageName extends keyof typeof configs,
  TProps extends PropsTypeFromPageConfig<typeof configs[TPageName]>
>({
  cookies,
  pageName,
  urlParams,
  currentUser = undefined,
}: PreCheckoutPageParams<TPageName>): Promise<
  | {
      cookieChanges: CookieJar;
      analyticsEvents: AnalyticEvent[];
      redirect: PageName | "login" | "accountPage";
      props?: undefined;
    }
  | {
      cookieChanges: CookieJar;
      analyticsEvents: AnalyticEvent[];
      props: TProps;
      redirect?: undefined;
    }
> {
  const cookieChanges: CookieJar = {};
  const analyticsEvents: AnalyticEvent[] = [];

  // TODO it would be good to avoid converting to unknown here
  const pageConfig = configs[pageName] as unknown as PageConfig<
    TProps,
    FlowData,
    CurrentUser
  >;

  if (pageConfig.mustBeLoggedIn && !currentUser) {
    return {
      redirect: "login",
      cookieChanges,
      analyticsEvents,
    };
  }

  if (currentUser && currentUser.hasSubscription) {
    return {
      redirect: "accountPage",
      cookieChanges,
      analyticsEvents,
    };
  }

  // load the data for the checkout flow from cookies
  let flowData = getFlowData("checkout", cookies, currentUser);

  //set user login to data
  flowData.isLoggedIn = !!currentUser;

  // if applicable, override flow data with values from URL parameters
  if (urlParams) {
    const updatedFlowData = applyValuesFromUrlParams(urlParams, flowData);
    // fire event if data changed based on url params
    if (updatedFlowData) {
      flowData = updatedFlowData;

      analyticsEvents.push("options-change-url");
      updateCheckoutCookies("checkout", cookieChanges, flowData);
    }
  }

  // validate each activated step before this, earliest first...
  for (const previousActivatedStepName of pageConfig.previousActivatedSteps) {
    const previousActivatedStep = configs[previousActivatedStepName];

    try {
      await previousActivatedStep.validateStep(flowData, urlParams);
    } catch (e) {
      // e is validationerror
      // TODO add reasons for redirect
      Sentry.setExtra("flowData", flowData);
      Sentry.captureException(e);

      analyticsEvents.push("rewind");
      return {
        redirect: previousActivatedStepName,
        cookieChanges,
        analyticsEvents,
      };
    }
  }

  // if this page is not the last one, and can be skipped (e.g. it only needs to be filled in correctly once and is not changeable), then possibly redirect to the next step
  if (pageConfig.nextStep) {
    const canBeSkipped = await pageConfig.canBeSkipped(
      flowData,
      currentUser,
      urlParams
    );
    if (canBeSkipped) {
      analyticsEvents.push("fast-forward");
      return {
        redirect: pageConfig.nextStep,
        cookieChanges,
        analyticsEvents,
      };
    }
  }

  return {
    props: await pageConfig.getPageProps(flowData, urlParams, currentUser),
    cookieChanges,
    analyticsEvents,
  };
}

async function getCookies(
  context?: GetServerSidePropsContext
): Promise<CookieJar> {
  return nookies.get(context);
}

async function getCurrentUser(
  context: GetServerSidePropsContext
): Promise<CurrentUser | undefined> {
  const session = await getSession({ ctx: context });
  if (session) {
    const { user } = session;

    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      hasSubscription: user.hasSubscription,
      phone_number_mobile: user.phoneNumberMobile || undefined,
      isRejoiner: user.isRejoiner ?? false,
      requestUrl: user.requestUrl || undefined,
      home_address_line_one: user.homeAddress?.addressLineOne,
      home_address_line_two: user.homeAddress?.addressLineTwo || undefined,
      home_city: user.homeAddress?.city,
      home_postcode: user.homeAddress?.postcode,
      dateOfBirth: user.birthDate
        ? format(parseISO(user.birthDate), "dd/MM/yyyy")
        : undefined,
    };
  }
  return;
}

async function applyCookieChanges(
  cookieChanges: CookieJar,
  context?: GetServerSidePropsContext
) {
  // TODO we might want long-lived cookies, different Path option, etc.
  Object.entries(cookieChanges).map(([name, value]) => {
    nookies.set(context, name, value, { path: "/" });
  });
}

async function applyAnalyticsEvents(
  analyticsEvents: AnalyticEvent[],
  _context: GetServerSidePropsContext
) {
  await Promise.all(
    analyticsEvents.map(async (event) => {
      // TODO apply analytics event
      switch (event) {
        case "rewind":
          break;
        case "options-change-url":
          break;
        case "fast-forward":
          break;
      }
    })
  );
}

function responseForRedirect(
  redirect: PageName | "login" | "accountPage",
  context: GetServerSidePropsContext
): { redirect: Redirect } {
  const queryString = makeQueryString(context.query);

  switch (redirect) {
    case "account":
      return {
        redirect: {
          permanent: false,
          destination: `/a/70-account${queryString}`,
        },
      };
    case "login":
      return {
        redirect: { permanent: false, destination: "/login" },
      };
    case "payment":
      return {
        redirect: {
          permanent: false,
          destination: `/a/80-pay${queryString}`,
        },
      };
    case "accountPage":
      return {
        redirect: {
          permanent: false,
          destination: `/a/70-account${queryString}`,
        },
      };
    case "plan": {
      return {
        redirect: {
          permanent: false,
          destination: `/a/60-plan${queryString}`,
        },
      };
    }
    case "kitshop": {
      return {
        redirect: {
          permanent: false,
          destination: `/a/71-kitshop${queryString}`,
        },
      };
    }
    case "city": {
      return {
        redirect: {
          permanent: false,
          destination: `/a/50-city${queryString}`,
        },
      };
    }
  }
}

export function getCheckoutPageHandler<TPageName extends PageName>(
  pageName: TPageName
): GetServerSideProps<
  PropsTypeFromPageConfig<typeof configs[TPageName]>,
  UrlQuery
> {
  return async (context: GetServerSidePropsContext) => {
    if (
      pageName === "plan" &&
      context.query.ranMID &&
      context.query.ranSiteID
    ) {
      const now = new Date();
      const cookieValue = {
        amid: context.query.ranMID,
        ald: formatInTimeZone(now, "yyyyMMdd_HHmm", "UTC"),
        auld: Math.round(now.getTime() / 1000),
        atrv: context.query.ranSiteID,
      };
      const cookieString = Object.entries(cookieValue)
        .reduce((acc, [key, val]) => acc + key + ":" + val + "|", "")
        .slice(0, -1);

      setCookie(context, "rmStoreGateway", cookieString, {
        domain: ".buzzbike.cc",
        path: "/",
        secure: true,
        sameSite: "Lax",
        maxAge: 30 * 24 * 3600,
      });
    }

    const cookies = await getCookies(context);
    const currentUser = await getCurrentUser(context);

    const res = await preCheckoutPage({
      pageName,
      cookies,
      urlParams: context.query,
      currentUser,
    });

    await applyCookieChanges(res.cookieChanges, context);

    await applyAnalyticsEvents(res.analyticsEvents, context);

    if (res.redirect) {
      return responseForRedirect(res.redirect, context);
    }

    return {
      props: {
        ...res.props,
        query: context.query,
      },
    };
  };
}
export type PropsFromHandler<THandler> = THandler extends GetServerSideProps<
  infer T,
  infer U
>
  ? T & { query: U }
  : never;

export const removeUndefinedForNextJsSerializing = <T>(props: T): T => {
  const newProps: Record<string, unknown> = {};
  Object.entries(props).forEach(([key, value]) => {
    if (value !== undefined) {
      newProps[key] = value;
    }
  });
  return newProps as T;
};

export type SelectedKit = {
  kitInventoryId: number;
  quantity: number;
  price: number;
};
