import { JZ } from "@buzzbike/ui/src/DesignSystem";
import React from "react";

const TrustBox = ({
  theme = "light",
  templateId = "5406e65db0d04a09e042d5fc",
}: {
  theme?: "light" | "dark";
  templateId?: string;
}) => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id={templateId}
      data-businessunit-id="5df3bd885df7110001c96280"
      data-style-height="28px"
      data-style-width="100%"
      data-theme={theme}
      data-text-color={theme === "light" ? JZ.BuzzBlack : "white"}
    >
      <a
        href="https://uk.trustpilot.com/review/buzzbike.cc"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};
export default TrustBox;
