import { JZ } from "@buzzbike/ui/src/DesignSystem";
import {
  Box,
  BoxProps,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import Image from "next/image";
import { useRouter } from "next/router";
import { signOut, useSession } from "next-auth/client";
import { NextSeo } from "next-seo";
import { FC, useCallback, useState } from "react";

import BikeEImage from "/public/static/images/signup-image-bikeE.png";
import BikeImage from "/public/static/images/signup-image-buzzbike.jpeg";

// import Banner from "components/Banner";
import ButtonLink from "components/ButtonLink";
import { CloseIcon } from "components/icon/CloseIcon";
import { Logo } from "components/Logo";
import { destroyCheckoutCookies, SelectedKit } from "utils/checkout";

import { BBEZ_WAITLIST, BBEZ_WAITLIST_EXCLUDE_CITY } from "../../constants";
import CustomIconButton from "../CustomIconButton";
import { BackIcon } from "../icon/BackIcon";
import BuyBikeIcon from "../icon/illustrations/BuyBikeIcon";
import RepairsIcon from "../icon/illustrations/RepairsIcon";
import RewardIcon from "../icon/illustrations/RewardIcon";
import TheftIcon from "../icon/illustrations/TheftIcon";
import SignupNavbar from "../navbar/SignupNavbar";
import PrimaryButton from "../PrimaryButton";
import { CheckoutBreadcrumb } from "./Breadcrumb";

const SignupLayout: FC<{
  step?: string;
  containerStyles?: BoxProps;
  childrenStyles?: BoxProps;
  bikeE?: boolean;
  isSubmitting?: boolean;
  buttonText?: string;
  formId?: string;
  planPrice?: string;
  seletedKits?: SelectedKit[];
  totalToday?: number;
  delivery?: number | null;
  isCyclescheme?: boolean;
  cityName?: string;
  isShowBanner?: boolean;
  onBack?: () => Promise<void>;
  onContinue?: () => Promise<void>;
}> = ({
  children,
  step,
  containerStyles,
  childrenStyles,
  bikeE = false,
  isSubmitting = false,
  buttonText,
  formId,
  planPrice,
  cityName,
  // isShowBanner,
  // delivery,
  // seletedKits = [],
  // totalToday,
  isCyclescheme,
  onBack,
  onContinue,
}) => {
  const isTablet = useBreakpointValue({
    base: true,
    lg: false,
  });
  const router = useRouter();
  const [session] = useSession();

  const [isBacking, setIsBacking] = useState<boolean>(false);
  const [isContinuing, setIsContinuing] = useState<boolean>(false);

  const isStep0 = step && step === "step0";

  // const calculateTotalToday = useCallback(() => {
  //   return (
  //     (delivery || 0) +
  //     seletedKits
  //       ?.map(({ price, quantity }) => price * 100 * quantity)
  //       .reduce((sum, i) => sum + i, 0)
  //   );
  // }, [delivery, seletedKits]);

  const onClose = useCallback(async () => {
    destroyCheckoutCookies("checkout");
    if (session) {
      await signOut({ redirect: false });
    }
    router.push("/login");
  }, [router, session]);

  const hanldeOnBack = async () => {
    if (onBack) {
      setIsBacking(true);
      await onBack();
      setIsBacking(false);
    }
  };
  const handleOnContinue = async () => {
    if (onContinue) {
      setIsContinuing(true);
      await onContinue();
      setIsContinuing(false);
    }
  };
  const isEZWaitlist =
    bikeE &&
    BBEZ_WAITLIST &&
    !isCyclescheme &&
    cityName !== BBEZ_WAITLIST_EXCLUDE_CITY &&
    router.query.fromBBEWaitingList !== "true";

  return (
    <>
      <NextSeo
        noindex
        nofollow
        title={`${isCyclescheme ? "Cyclescheme Signup" : "Signup"} - Buzzbike`}
      />
      {/* <Banner isShow={isCyclescheme ? false : isShowBanner} /> */}
      {!isTablet && !isCyclescheme && (
        <SignupNavbar logoColor={bikeE ? "white" : "pink"} withTrustBox />
      )}
      <HStack alignItems="flex-start" spacing={0} {...containerStyles}>
        {!isTablet && (
          <Box
            bgColor={bikeE ? JZ.Pink : "#f1f4fa"}
            flex={3 / 5}
            h="100vh"
            position="sticky"
            top={0}
          >
            <Image
              src={bikeE ? BikeEImage : BikeImage}
              alt="left side image"
              layout="fill"
              objectFit="cover"
              placeholder="blur"
              sizes="60vw"
            />
            {isCyclescheme && (
              <Stack
                position={"absolute"}
                top={20}
                w="100%"
                alignItems="center"
                spacing={4}
              >
                <Logo iconColor={JZ.White} h={8} />
                <CloseIcon color={JZ.White} />
                <Box>
                  <Image
                    src="/static/images/cyclescheme-logo.png"
                    width={100}
                    height={60}
                  />
                </Box>
              </Stack>
            )}
            <HStack
              rounded={16}
              bg={JZ.White}
              shadow="sm"
              position={"absolute"}
              bottom={25}
              left="5%"
              w="90%"
              py={4}
              justifyContent={"space-around"}
            >
              <VStack>
                <RepairsIcon color={JZ["Pink/1.5"]} />
                <Text {...JZ["Caption/14 Med"]} color={JZ.BuzzBlack}>
                  Repairs
                </Text>
              </VStack>
              <VStack pl={4}>
                <TheftIcon color={JZ.Joyful} />
                <Text {...JZ["Caption/14 Med"]} color={JZ.BuzzBlack}>
                  Theft replacement
                </Text>
              </VStack>
              <VStack>
                <RewardIcon color={JZ.Mint} />
                <Text {...JZ["Caption/14 Med"]} color={JZ.BuzzBlack}>
                  Rewardz
                </Text>
              </VStack>
              <VStack>
                <BuyBikeIcon color={JZ["Pink/1.5"]} />
                <Text {...JZ["Caption/14 Med"]} color={JZ.BuzzBlack}>
                  Buy your bike
                </Text>
              </VStack>
            </HStack>
          </Box>
        )}
        <Box flex={1}>
          <Stack
            direction={"row"}
            flex={1}
            px={[4, 5]}
            pb={24}
            mt={isTablet ? 20 : 0}
            spacing={0}
          >
            <Box
              flex={1}
              pl={isTablet ? 0 : 14}
              pt={isTablet ? 4 : isStep0 ? 5 : 8}
              maxW="5xl"
              mx="auto"
            >
              <HStack alignItems="center" justifyContent={"flex-end"}>
                {step ? (
                  <>
                    {!isStep0 && !isEZWaitlist && (
                      <Box w="100%">
                        <CheckoutBreadcrumb currentPage={step} />
                      </Box>
                    )}{" "}
                  </>
                ) : null}
              </HStack>
              <Box {...childrenStyles}>{children}</Box>
              {isTablet && !isStep0 && !isEZWaitlist && (
                <HStack mt={4}>
                  <CustomIconButton
                    icon={<BackIcon color={JZ.Pink} />}
                    aria-label={"Back"}
                    isLoading={isBacking}
                    onClick={hanldeOnBack}
                    bgColor={JZ["Pink/1"]}
                    rounded={12}
                    boxSize="14"
                    _active={{ background: JZ["Pink/1.5"], color: "#CA1244" }}
                    _hover={{ background: "#FFE4EB", color: JZ["Pink/3"] }}
                  />
                  <PrimaryButton
                    flex={1}
                    type="submit"
                    isLoading={isSubmitting}
                    onClick={handleOnContinue}
                    form={formId}
                  >
                    {buttonText || "Continue"}
                  </PrimaryButton>
                </HStack>
              )}
            </Box>
            {!isTablet && (
              <Stack
                direction={"row"}
                spacing={4}
                alignItems="flex-start"
                pt={5}
                pl={4}
              >
                {isStep0 && !session && (
                  <ButtonLink
                    {...JZ["Web/Caption/16 Med"]}
                    textDecoration="none"
                    onClick={onClose}
                  >
                    Log In
                  </ButtonLink>
                )}
                <CustomIconButton
                  icon={<CloseIcon color={JZ.BuzzBlack} />}
                  onClick={onClose}
                  aria-label={"Close"}
                  size="xs"
                />
              </Stack>
            )}
          </Stack>
          {step && (!isEZWaitlist || isTablet) && (
            <Stack
              px={4}
              py={3}
              display={!isTablet && isStep0 ? "none" : "flex"}
              spacing={3}
              direction={isTablet ? "column" : "row"}
              justifyContent="space-between"
              w={["100%", "100%", "100%", "62.5%"]}
              position="fixed"
              zIndex={2}
              {...(isTablet
                ? { top: 0, borderBottom: `1px solid ${JZ["Background 2"]}` }
                : {
                    bottom: 0,
                    borderTop: `1px solid ${JZ["Background 2"]}`,
                  })}
              bg={JZ.White}
            >
              {isTablet && (
                <HStack justifyContent={"space-between"} flex={1}>
                  <Logo iconColor={JZ.Pink} h={4} />
                  <Stack direction={"row"} spacing={4} alignItems="flex-start">
                    {isStep0 && !session && (
                      <ButtonLink
                        {...JZ["Web/Caption/16 Med"]}
                        textDecoration="none"
                        onClick={onClose}
                      >
                        Log In
                      </ButtonLink>
                    )}
                    <CustomIconButton
                      icon={<CloseIcon color={JZ.BuzzBlack} />}
                      onClick={onClose}
                      aria-label={"Close"}
                      size="xs"
                    />
                  </Stack>
                </HStack>
              )}
              <HStack>
                {!isTablet && onBack && (
                  <CustomIconButton
                    icon={<BackIcon color={JZ.BuzzBlack} />}
                    aria-label={"Back"}
                    isLoading={isBacking}
                    onClick={hanldeOnBack}
                  />
                )}
                {!isStep0 && !isEZWaitlist && (
                  <HStack>
                    <Stack {...JZ["Caption/14 Med"]} color={JZ["Darkest Gray"]}>
                      <Text>Subscription</Text>
                    </Stack>
                    <Stack {...JZ["Caption/14 Med"]} color={JZ.BuzzBlack}>
                      <Text>{planPrice}</Text>
                    </Stack>
                  </HStack>
                )}
              </HStack>
              {!isTablet && (
                <PrimaryButton
                  type="submit"
                  isLoading={isSubmitting || isContinuing}
                  onClick={handleOnContinue}
                  minW={72}
                  form={formId}
                >
                  {buttonText || "Continue"}
                </PrimaryButton>
              )}
            </Stack>
          )}
        </Box>
      </HStack>
    </>
  );
};
export default SignupLayout;
